import React from "react"

import Layout from "../components/Layout"
import { HospitalTable } from "../components/HospitalTable"

const Patients = ({ location }) => (
  <Layout pathname={location.pathname} title={"Patients in hospital"}>
    <h3 className="title is-3">Nations</h3>
    <HospitalTable
      areaType="nation"
      field="hospitalCases"
      label="Patients in hospital"
      per100kLabel="Patients in hospital"
    />
    <h3 className="title is-3">NHS regions</h3>
    <HospitalTable
      areaType="nhsregion"
      field="hospitalCases"
      label="Patients in hospital"
      per100kLabel="Patients in hospital"
    />
  </Layout>
)

export default Patients
